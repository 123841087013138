<template>
    <div class="uk-padding-remove uk-margin-remove-adjacent">
        <insert/>
    </div>
</template>

<script>
import insert from '@/views/Insert'
export default {
    components:{
        insert
    }
}
</script>

<style>
#cards {
    margin-top: 0px !important;
}
</style>