<template>
<div class="uk-container uk-container-expand" id="cards">
    <form @submit.prevent="insert()">
        <div class="uk-grid-match uk-child-width-1-2@s" uk-grid>
            <div class="uk-grid-item-match">
                <div class="uk-card-default uk-card-body">
                    <h3 class="uk-card-title">{{"Auto" | localize}}</h3>
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">{{"Mark" | localize}}</label>
                            <select class="uk-select" v-model="selectedMark" v-on:change="getModel(selectedMark.value)">
                                <option v-for="mark in marks" v-bind:value="mark">
                                    {{ mark.text | trimBracket}}
                                </option>
                            </select>
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label ">{{"Model" | localize}}</label>
                            <select class="uk-select" v-model="selectedModel" v-on:change="getGeneration(selectedModel.value)">
                                <option v-for="model in models" v-bind:value="model">
                                    {{ model.text }}
                                </option>
                            </select>
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label ">{{"Generation" | localize}}</label>
                            <select class="uk-select" v-model="selectedGeneration" >
                                <option v-for="generation in generations" v-bind:value="generation">
                                    {{ generation.text }}
                                </option>
                            </select>
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">{{"Year" | localize}}</label>
                            <input class="uk-input" type="text"  v-model="selectedYear">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Engine_code" | localize}}</label>
                            <input class="uk-input" type="text"  v-model="selectedEngineCode">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"EngineVolume" | localize}}</label>
                            <input class="uk-input" type="text"  v-model="selectedEngineVolume">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label ">{{"EngineType" | localize}}</label>
                            <select class="uk-select" v-model="selectedEngineType">
                                <option value="1"> {{localizeFilter("Gas")}} </option>
                                <option value="2"> {{localizeFilter("Diesel")}} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-card-default uk-card-body">
                    <h3 class="uk-card-title">{{"Catalytic" | localize}}</h3>
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">{{"Catalic_code" | localize}}</label>
                            <input class="uk-input" type="text" v-model="selectedCatalic_code">
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">{{"Description" | localize}}</label>
                            <input class="uk-input" type="text" v-model="description">
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">{{'Comment' | localize}}</label>
                            <input class="uk-input" type="text" v-model="comment">
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">{{'WeightInsert' | localize}}</label>
                            <input class="uk-input" type="text" v-model="selectedWeight" required>
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label ">{{"Country" | localize}}</label>
                            <select class="uk-select" v-model="selectedCountry" required>
                                <option v-for="country in countries" v-bind:value="country">
                                    {{ country.text | localize}}
                                </option>
                            </select>
                        </div>
                        <!--
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label ">{{"Country" | localize}}</label>
                            <select class="uk-select" v-model="selectedCountry" required>
                                <option v-for="country in countries" v-bind:value="country">
                                    {{ country.text }}
                                </option>
                            </select>
                        </div>
                        -->
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">{{"Type" | localize}}</label>
                            <select class="uk-select" v-model="selectedType" required>
                                <option value="1"> {{"Металлический Россия" | localize}} </option>
                                <option value="2"> {{"Сажевый фильтр" | localize}} </option>
                                <option value="3"> {{"Керамический" | localize}} </option>
                                <option value="4"> {{"Металло-Керамика" | localize}} </option>
                                <option value="5"> {{"Металлический импорт" | localize}} </option>
                                <option value="6"> {{"Металлический BMW" | localize}} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-card-default uk-card-body">
                    <h3 class="uk-card-title">{{"Analysis" | localize}}</h3>
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Pt" | localize}}</label>
                            <input class="uk-input" type="number" step="0.001" v-model="pt">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Pd" | localize}}</label>
                            <input class="uk-input" type="number" step="0.001" v-model="pd">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Rh" | localize}}</label>
                            <input class="uk-input" type="number" step="0.001" v-model="rh">
                        </div>
                        <h3 class="uk-card-title">{{"Tags" | localize}}</h3>
                        <div class="uk-width-1-1">
                             <textarea class="uk-textarea" rows="1" :placeholder="localizeFilter('PlaceholderTags')" v-model="tags" ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-card-default uk-card-body">
                    <h3 class="uk-card-title">{{"Photo" | localize}}</h3>
                    <!--
                    <ul class="uk-thumbnav" uk-margin>
                        <li class="uk-active">
                            <a><img src="/images/1.png" width="100" alt=""></a>
                        </li>
                        <li>
                            <a><img src="/images/2.png" width="100" alt=""></a>
                        </li>
                        <li>
                            <a><img src="/images/3.png" width="100" alt=""></a>
                        </li>
                    </ul>
                    -->
                    <div class="js-upload uk-placeholder uk-text-center">
                        <ul class="uk-thumbnav" uk-margin>
                            <li class="uk-active" v-for="(img, cnt) in urls">
                                <a @click="deleteImage(cnt)">
                                    <img :src="img.url" width="200" alt="">
                                    <div class="uk-overlay-default uk-position-cover"></div>
                                    <div class="uk-overlay uk-position-center uk-dark">
                                        <span uk-icon="icon:close; ratio: 2"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <span uk-icon="icon: cloud-upload"></span>
                        <span class="uk-text-middle"> Drag-and-drop | </span>
                        <form  enctype="multipart/form-data" uk-form-custom id="form">
                            <input type="file" id="file" ref="files" multiple v-on:change="onChangeFileUpload()"  accept="image/jpeg">
                            <span class="uk-link">{{"Select_photo" | localize}}</span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <button class="uk-button uk-button-primary uk-border-rounded uk-width-1-1@s uk-width-1-3@l uk-align-center uk-margin-large-bottom uk-margin-medium-top"> {{"Save" | localize}} </button>
    </form>
</div>
</template>

<script>
import {eventBus} from '@/main'
import localizeFilter from '@/filter/localize.filter'
import changeToDots from '@/filter/dots.filter'
import store from '@/store'
import getMarks from '@/config/GetMarks.general'
import getModel from '@/config/GetModels.general'
import getGeneration from '@/config/GetGeneration.general'
import getCountries from '@/config/GetCountries.general'
import Journal from '@/config/Journal.dashboard'

/* VALIDATOR
import { extend } from 'vee-validate';
import { double, email } from 'vee-validate/dist/rules';

// No message specified.
extend('double', double);
*/


export default {
    metaInfo:{
        title: localizeFilter('TitleInsert')
    },
    data: () => ({
        mark: '',
        model: '',
        generation:'',
        country:'',
        tags: '',

        marks: [],
        models: [],
        generations:[],
        countries: [],

        selectedMark: '',
        selectedModel: '',
        selectedGeneration: '',
        selectedYear: '',
        selectedCountry:'',
        selectedEngine_code: '',
        selectedCatalic_code: '',
        selectedType: '',
        selectedWeight:'',
        selectedEngineCode: '',
        selectedEngineVolume: '',
        selectedEngineType: '',
        description: '',
        comment: '',
        pt: 0,
        pd: 0,
        rh: 0,

        files: [],
        urls: [],

        llgeneration: [],
        allmarks: [],
        allmodels: []
    }),
    name: 'Filters',
    methods:{
        getMarks,
        getModel,
        getGeneration,
        getCountries,
        localizeFilter,
        insert(){
            if(!Number.isInteger(parseInt(this.selectedWeight.replace('.', ',')))){
                UIkit.notification({message: localizeFilter('Weight_non_integer')})
            }
            else if(this.selectedEngineVolume != '' && !Number.isInteger(parseInt(this.selectedEngineVolume.replace('.', ',')))){
                UIkit.notification({message: localizeFilter('Engine_non_integer')})
            }
            else if(this.pt != '' && !Number.isInteger(parseInt(this.pt.replace('.', ',')))){
                UIkit.notification({message: localizeFilter('Pt_non_integer')})
            }
             else if(this.pd != '' && !Number.isInteger(parseInt(this.pd.replace('.', ',')))){
                UIkit.notification({message: localizeFilter('Pd_non_integer')})
            }
             else if(this.rh != '' && !Number.isInteger(parseInt(this.rh.replace('.', ',')))){
                UIkit.notification({message: localizeFilter('Rh_non_integer')})
            }
            else{
                let loader = this.$loading.show({
                    canCancel: false
                });

                axios.get('/catalic.php',
                    {
                        params:
                        {
                            type: 1,

                            mark : this.selectedMark.value,
                            model: this.selectedModel.value,
                            generation: this.selectedGeneration.value,
                            year: this.selectedYear,
                            engine_code: this.selectedEngineCode,
                            engine_volume: changeToDots( this.selectedEngineVolume),
                            engine_type: this.selectedEngineType,

                            code: this.selectedCatalic_code,
                            description: this.description,
                            comment: this.comment,
                            weight: changeToDots(this.selectedWeight),
                            country: this.selectedCountry.value,
                            type_cat: this.selectedType,

                            pt: changeToDots(this.pt),
                            pd: changeToDots(this.pd),
                            rh: changeToDots(this.rh),
                            photopass: this.files !='' ? 1 : 0,

                            tags: this.tags
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if(response.data.status == 1){
                            Journal(localStorage.getItem('login'), 'Добавлен катализатор. Номер: '  + this.selectedCatalic_code +
                            '. Авто: ' + this.selectedMark.text + ' ' + this.selectedModel.text + ' ' + this.selectedGeneration.text)
                            if(this.files != ''){
                                if(response.data.auto){
                                      Journal(localStorage.getItem('login'), 'Создан авто ' + this.selectedMark.text + ' ' + this.selectedModel.text + ' ' + this.selectedGeneration.text)
                                    UIkit.notification({message: localizeFilter('Car_insert'), timeout: 15000})
                                }
                                this.$data.models = response.data;
                                UIkit.notification({message: localizeFilter('Catalityc_insert'), timeout: 15000})
                                this.loadImages(response.data.id)
                                loader.hide()
                            }else{
                                if(response.data.auto){
                                    Journal(localStorage.getItem('login'), 'Создан авто ' + this.selectedMark.text + ' ' + this.selectedModel.text + ' ' + this.selectedGeneration.text)
                                    UIkit.notification({message: localizeFilter('Car_insert'), timeout: 15000})
                                }
                                this.$data.models = response.data;
                                UIkit.notification({message: localizeFilter('Catalityc_insert'), timeout: 15000})
                                loader.hide()
                            }

                        }
                        else{
                            loader.hide()
                            UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                        }
                        this.selectedCatalic_code= ''
                        this.selectedMark= ''
                        this.selectedModel= ''
                        this.selectedGeneration= ''

                    })
                    .catch(error =>{
                        console.log(error)
                        UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                        loader.hide()
                    })
                this.mark = '',
                this.model = '',
                this.generation = '',
                this.country ='',

                this.models = [],
                this.generations =[],

                this.selectedYear = '',
                this.selectedCountry = '',
                this.selectedEngine_code = '',
                this.selectedType = '',
                this.selectedWeight ='',
                this.selectedEngineCode = '',
                this.selectedEngineVolume = '',
                this.selectedEngineType = '',
                this.description = '',
                this.comment = ''

                this.pt = '',
                this.pd = '',
                this.rh = '',
                this.tags = ''

                this.urls = []
            }
        },
        onChangeFileUpload(){
            if(this.files.length == 0){
                this.files = Array.from(this.$refs.files.files)
            }
            else{
                Array.from(this.$refs.files.files).forEach((i) => {
                    this.files.push(i)
                })
                //this.files.push(Array.from(this.$refs.files.files)[0])
            }
            //this.files = this.$refs.files.files
            //this.files.append(Array.from(this.$refs.files.files))
            this.urls = []
            this.files.forEach((i) => {
                this.urls.push({
                    url: URL.createObjectURL(i)
                })
            })
        },
        deleteImage(cnt){
            this.urls.splice(cnt,1)
            this.files.splice(cnt,1)
        },
        loadImages(id){
             let loader = this.$loading.show({
                  // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false
            });
            let formData = new FormData();

            this.files.forEach((x) => {
                formData.append('files[]', x)
            })

            formData.append('id', id)

            axios({
                method: 'POST',
                url: '/imageloader.php',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function(data){
                UIkit.notification({message: localizeFilter('Images_loaded'), timeout: 15000})
                loader.hide()
            })
            .catch(function(){
                UIkit.notification({message: localizeFilter('Images_loaded_error')})
                loader.hide()
            });
            this.files = ''
        },
        createURL(img){
            return URL.createObjectURL(img)
        }
    },
    beforeMount(){
        this.getMarks()
        this.getCountries()
    }
}
</script>

<style scoped>
@media screen and (max-width: 960px){
	#cards {
        margin-bottom: 20px;
	}
}
#cards{
    margin-top: 100px;
}
.uk-overlay-default {
    background: rgba(255,255,255,.3) !important;
}
#banner{
 display: none;
}
</style>
